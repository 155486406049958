.NavigationBar {
  display: flex;
}

.Links {
  margin-top: 1%;
  margin-left: 2%;
}

.AboutLink {
  margin-left: 10%;
}

.LoginLink {
  margin-left: 10%;
}

.LogoText {
  margin-left: 4%;
}