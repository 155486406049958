.HookField {
    text-align: left;
    display: flex;
}
.InfomationFieldOne {
    text-align: left;
    margin-left: 25%;
    margin-top: 2%;
}
.InfomationFieldTwo {
    margin-left: -2%;
    margin-top: 10%;
}
.InfomationFieldThree {
    margin-left: -27.5%;
    margin-top: 15%;
}
.InfomationFieldFour {
    margin-left: 0.5%;
    margin-top: 20%;
}
.InfomationFieldFive {
    margin-left: -28.5%;
    margin-top: 25%;
}